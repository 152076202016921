<template>
  <div class="m3-flex-col m3-align-items-start m3-padding-horizontal-l">
    <div class="m3-flex m3-justify-content-end m3-padding-l">
      <SearchForm :amount-option="amountList" @change="toSearch" @search="toSearch"
                  @add="editRecord(null)"></SearchForm>
    </div>
    <a-table class="a-table m3-width-full"
             :dataSource="tableList" :columns="tableColumns"
             :scroll="{ x: 1400 }"
             :loading="listLoading" size="small"
             :row-class-name="tableRowClassName"
             :pagination="pagination">
      <template #id="{record}">
        <div class="m3-line-clamp-1">{{ record.orderNo }}</div>
      </template>
      <template #date="{record}">
        <div class="m3-line-clamp-1">{{ record.createTime }}</div>
      </template>
      <template #line="{record}">
        <div class="m3-line-clamp-1">{{ record.amountName }}</div>
      </template>
      <template v-if="isAdmin" #user="{record}">
        <div class="m3-line-clamp-1">{{ record.nickname }}</div>
      </template>
      <template #tel="{record}">
        <div class="m3-line-clamp-1">主叫：{{ record.caller }}</div>
        <div class="m3-line-clamp-1 m3-margin-top-s">被叫：{{ record.callee }}</div>
      </template>
      <template v-if="isAdmin" #source="{record}">
        <div class="m3-line-clamp-1">{{ orderFromDesc(record) }}</div>
      </template>
      <template #progress="{record}">
        <div class="m3-flex-col m3-align-items-start">
          <div class="m3-line-clamp-1">{{ statusDesc(record) }}</div>
          <div v-if="record.status >= 18 && punishTypeDesc(record)" class="m3-flex-col m3-text-label">
            <span class="m3-line-clamp-1">处罚结果：</span>
            <div class="m3-flex m3-margin-top-xs">
              <span disabled class="">{{ punishTypeDesc(record) }}</span>
              <span v-if="punishValueDesc(record)" class="m3-margin-horizontal-s">-</span>
              <span v-if="punishValueDesc(record)"
                    disabled class="">{{ punishValueDesc(record) }}
              </span>
            </div>
          </div>
        </div>

      </template>
      <!--      <template #riskText="{record}">-->
      <!--        <div :class="riskTextClass(record)"-->
      <!--             class="m3-padding-horizontal-xs m3-padding-vertical-xs m3-text-center md-label-small">-->
      <!--          {{ record.riskText }}-->
      <!--        </div>-->
      <!--      </template>-->
      <template #handle="{record}">
        <a-button v-if="isAdmin" type="primary"
                  @click="editRecord(record)">编辑
        </a-button>
        <a-button v-else-if="canUserAppeal(record)"
                  type="primary"
                  @click="editRecord(record)">{{ canUserAppeal(record) ? '申诉' : '查看' }}
        </a-button>
        <a-button v-if="isAdmin&&canInvalidate(record)"
                  type="primary"
                  class="m3-margin-left-s"
                  @click="invalidateRecord(record)">作废
        </a-button>
        <a-button v-if="record.checkId"
                  type="primary"
                  class="m3-margin-left-s"
                  @click="editLineRecord(record.checkId)">查看话单
        </a-button>
      </template>
    </a-table>

    <RecordDialog v-model:dialog-visible="recordDialogVisible"
                  :record="currentRecord"
                  @show-line-record-dialog="editLineRecord"
                  @confirm="recordDialogConfirm"/>

    <LineRecordDialog ref="lineRecordDialogRef"
                      :isAdminUser="isAdminUser"
                      :amount-option="amountList"
                      :show-bottom-handle="false"
                      @nextData="nextLineRecord"
                      :rowJson="currentLineRecord"
                      v-model="lineRecordDialogVisible"
                      @success="lineRecordDialogVisible = false"/>
  </div>
</template>

<script>
import {nextTick, computed, onMounted, reactive, ref, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import RecordDialog from "@/views/open/workOrder/RecordDialog.vue";
import LineRecordDialog from "@/views/open/LineQualityInspections/record-dialog.vue"
import {useStore} from "vuex";
import {orderFromDesc, punishTypeDesc, punishValueDesc, statusDesc} from "@/views/open/workOrder/WorkOrder";
import {industryQuery} from "@/api/open/industry/query";
import AButton from "ant-design-vue/lib/button/button";
import SearchForm from "@/views/open/workOrder/SearchForm.vue";
import {modalConfirm} from "@/utils/common/modalConfirm";
import {message} from "ant-design-vue";

export default {
  name: "Record",
  components: {SearchForm, AButton, RecordDialog, LineRecordDialog},
  setup(props, context) {

    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');
    // let isAdmin = computed(() => false);
    let isAdminUser = computed(() => store.getters?.userType);
    // console.log('Record store', store, store.getters?.userType)
    let recordDialogVisible = ref(false);
    let currentRecord = ref({});
    let lineRecordDialogRef = ref();
    let lineRecordDialogVisible = ref(false);
    let currentLineRecord = ref({});

    let searchForm = {};

    let list = ref([]);
    let listLoading = ref(false);
    let tableList = computed(() => {
      return list.value?.map(item => {
         return { ...item, key: item.id };
      })
    })
    let pagination = reactive({
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["10", "20", "30", "40"],
      // 显示总数
      showTotal: (total) => `共 ${total} 条`,
      // 改变每页数量时更新显示
      onShowSizeChange: (current, pageSize) => {
        // console.log('onShowSizeChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
      // 改变页数，跳转页数
      onChange: (current, pageSize) => {
        // console.log('onChange', current, pageSize)
        pagination.current = current;
        pagination.pageSize = pageSize;
        getList();
      },
    });

    let userColumn = {
      title: '用户',
      dataIndex: 'user',
      key: 'user',
      width: '200px',
      slots: {customRender: 'user'},
    };
    let sourceColumn = {
      title: '违规来源',
      dataIndex: 'source',
      key: 'source',
      width: '100px',
      slots: {customRender: 'source'},
    };
    let tableColumns = ref([
      {
        title: '违规工单号',
        key: 'id',
        dataIndex: 'id',
        width: '200px',
        slots: {customRender: 'id'},
      },
      {
        title: '日期',
        key: 'date',
        width: '200px',
        // dataIndex: 'date',
        slots: {customRender: 'date'},
      },
      {
        title: '线路名',
        key: 'line',
        dataIndex: 'line',
        width: '200px',
        slots: {customRender: 'line'},
      },
      // {
      //   title: '用户',
      //   dataIndex: 'user',
      //   key: 'user',
      //   width: '200px',
      //   slots: {customRender: 'user'},
      // },
      {
        title: '主被叫',
        // dataIndex: 'tel',
        key: 'tel',
        slots: {customRender: 'tel'},
      },
      // {
      //   title: '违规来源',
      //   dataIndex: 'source',
      //   key: 'source',
      //   width: '100px',
      //   slots: {customRender: 'source'},
      // },
      {
        title: '进度',
        dataIndex: 'progress',
        key: 'progress',
        slots: {customRender: 'progress'},
      },
      {
        title: '操作',
        dataIndex: 'handle',
        key: 'handle',
        width: '250px',
        fixed: 'right',
        slots: {customRender: 'handle'},
      },
    ]);

    watch(isAdmin, (newV, preV) => {
      if (newV) {
        let findUserColumnIndex = tableColumns.value.findIndex(item => item.title == userColumn.title);
        if (findUserColumnIndex === -1) {
          tableColumns.value.splice(3, 0, userColumn);
        }
        let findSourceColumnIndex = tableColumns.value.findIndex(item => item.title == sourceColumn.title);
        if (findSourceColumnIndex === -1) {
          tableColumns.value.splice(5, 0, sourceColumn);
        }
      } else {
        let findUserColumnIndex = tableColumns.value.findIndex(item => item.title == userColumn.title);
        if (findUserColumnIndex !== -1) {
          tableColumns.value.splice(findUserColumnIndex, 1);
        }
        let findSourceColumnIndex = tableColumns.value.findIndex(item => item.title == sourceColumn.title);
        if (findSourceColumnIndex !== -1) {
          tableColumns.value.splice(findSourceColumnIndex, 1);
        }
      }
    }, {immediate: true})
    let getList = async () => {
      // let params = toRaw(searchForm);
      let params = searchForm;
      let paginationParams = {
        page: pagination.current,
        pageSize: pagination.pageSize,
      };
      // console.log('getList', params);
      listLoading.value = true;
      let res = await safePromise(_lineService._workOrderService.getRecordList({...paginationParams, ...params}))
      // res.data?.records?.forEach((item, i) => {
      //   if (item.hitTypeDesc == null) {
      //     item.hitTypeDescArr = []
      //   } else {
      //     item.hitTypeDescArr = item.hitTypeDesc.split(',')
      //   }
      //   // item.translatedContent = getTranslatedContent(item);
      //   item.translatedContentHtml = getTranslatedContentHtml(item);
      // });
      // let testList = []
      // for (let i = 0; i < 10; i++) {
      //   testList.push({
      //     id: 'id' + i,
      //     date: '2023-04-28 12:0' + i,
      //     line: 'line' + i,
      //     user: 'user' + i,
      //     telA: 'telA' + i,
      //     telB: 'telB' + i,
      //     source: 'source' + i,
      //     progress: i,
      //   })
      // }
      // res.data.records = testList;
      pagination.total = res?.data?.total || 0;
      list.value = res?.data?.records || [];
      if (EmptyUtil.isEmpty(list.value) && pagination.current > 1) {
        //如果当前页面列表为空，且不是首页，页数回退一页，这样刷新是可以刷出上一页列表
        pagination.current--;
      }

      listLoading.value = false;
    }

    let toSearch = async (search) => {
      pagination.current = 1;//点击搜索时，重置为第一页
      searchForm = search;
      await getList();
    }

    let amountList = ref([]);
    let getAmountList = async () => {
      const res = await safePromise(_lineService._recordService.getAmountList())
      amountList.value = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
    }

    let industryList = ref([]);
    let getIndustryList = async () => {
      //用户话单详情行业字段界面展示
      industryQuery({showError: false}).then((res) => {
        let {code, data, message} = res
        if (code == 200) {
          industryList.value = data
        }
      })
    }
    let editLineRecord = async (id) => {
      //话单弹窗
      if (EmptyUtil.isEmpty(id)) {
        id = currentRecord.value.checkId;
      }
      if (EmptyUtil.isEmpty(id)) {
        await modalConfirm('暂无话单详情');
        return;
      }
      const res = await safePromise(_lineService._recordService.getRecordDetail({id: id}));
      if (res?.data) {
        let find = industryList.value.find((item) => item.id == res.data.businessType);
        res.data.industry = find?.name || '--';

        currentLineRecord.value = res.data;
        lineRecordDialogVisible.value = true;
        await nextTick()
        lineRecordDialogRef.value?.open();
      }
    }
    let nextLineRecord = async (offset) => {
      let res;
      if (offset != 0) {
        //下一个
        let findIndex = list.value?.findIndex(item => item.checkId == currentLineRecord.value?.id);
        // console.log('getNextTableData aaa', findIndex, list.value.length,)
        if (findIndex !== -1) {
          let nextIndex = findIndex + offset;
          if (nextIndex >= 0 && nextIndex <= list.value.length - 1) {
            res = list.value[nextIndex];
          } else if (offset < 0 && nextIndex < 0 && this.pageForm.currentPage > 1) {
            //加载上一页
            pagination.current -= 1;
            await getList();
            res = list.value[list.value.length - 1];
          } else if (offset > 0 && nextIndex > list.value.length - 1) {
            //加载下一页
            pagination.current += 1;
            await getList();
            res = list.value[0];
          }
        }
      }
      if (res) {
        await editLineRecord(res.checkId);
      }
    }

    let editRecord = (record) => {
      currentRecord.value = record || {checkId: null};
      recordDialogVisible.value = true;
    }

    let invalidateRecord = async (record) => {
      let confirm = await modalConfirm('确认作废？');
      if (!confirm) {
        return;
      }
      let res = await safePromise(_lineService._workOrderService.invalidate({id: record.id}));
      if (res?.code == 200) {
        record.status = 17;
      } else {
        message.error(res?.message || res?.msg || '提交失败');
      }
    }

    let recordDialogConfirm = async ({index}) => {
      // let nextRecord = await getNextRecord(index);
      // // console.log('recordDialogConfirm', nextRecord)
      // if (!EmptyUtil.isEmpty(nextRecord) && index != 0) {
      //   currentRecord.value = nextRecord;
      // }
    }

    onMounted(() => {
      // getList();
      getAmountList();
      getIndustryList();
    })
    return {
      isAdmin, isAdminUser,
      lineRecordDialogRef, lineRecordDialogVisible, currentLineRecord,
      editLineRecord, nextLineRecord, invalidateRecord,
      recordDialogVisible, currentRecord, editRecord, recordDialogConfirm,
      amountList, list, listLoading, tableList, tableColumns, pagination,
      toSearch, getList,
    }
  },
  computed: {
    tableRowClassName() {
      return (item, itemIndex) => {
        if (item.read === 1 || item.auditStatus === 1) {
          return 'a-tab-row-primary-light'
        } else if (item.auditStatus === 2) {
          return 'a-tab-row-error'
        } else if (item.auditStatus === 3) {
          return 'a-tab-row-warning'
        } else if (item.auditStatus === 4) {
          return 'a-tab-row-warning'
        }
        return '';
      }
    },
    orderFromDesc() {
      //来源 0-平台质检 1-运营商质检
      return (item) => {
        return orderFromDesc(item?.orderFrom)
      }
    },
    statusDesc() {
      //来源 0-平台质检 1-运营商质检
      return (item) => {
        return statusDesc(item?.status)
      }
    },
    canUserAppeal() {
      //来源 0-平台质检 1-运营商质检
      return (item) => {
        return item?.status == 12;
      }
    },
    canInvalidate() {
      //是否可作废
      return (item) => {
        return item?.status > 0 && item?.status < 17;
      }
    },
    punishTypeDesc() {
      return (item) => {
        return punishTypeDesc(item?.punishFinalType)
      }
    },
    punishValueDesc() {
      return (item) => {
        return punishValueDesc(item?.punishFinalType, item?.punishFinalVal)
      }
    },

  },
}
</script>

<style lang="less" scoped>
.a-table :deep(.ant-table-tbody) {
  .a-tab-row-error {
    background: #fcbebe;
  }

  .a-tab-row-warning {
    background: #f3dd94;
  }

  .a-tab-row-success {
    background: #b2edd9;
  }

  .a-tab-row-primary-light {
    background: #F0F5FF;
  }
}
</style>