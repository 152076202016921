<template>
  <a-config-provider :locale="locale">
    <div class="m3-width-full m3-height-full">
      <Record/>
    </div>

  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Record from "@/views/open/workOrder/Record.vue";

export default {
  name: "index",
  components: {Record},
  setup(props, context) {
    let locale = zhCN;

    return {
      locale,
    }
  }
}
</script>

<style scoped>

</style>